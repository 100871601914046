import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Section from '../components/Section';

import './_404.scss';


const Page404 = () => (
  <Layout
    className="Page404"
  >
    <Helmet>
      <html lang="en" />
      <title>404 - Not found :(</title>
      <meta
        name="description"
        content=""
      />
      <link rel="canonical" href="https://ca.customwritings.com/404.html" />
    </Helmet>

    <Header />

    <Section className="section-404 white-bg" />

  </Layout>
);

export default Page404;
